































































import Vue from 'vue'
import { cloneDeep as _cloneDeep } from 'lodash'
import { SelectOption } from '@/models/form'

export type FavoriteStorageName = 'exchange' | 'pair' | 'market' | 'productType'

type FilterInputType = Vue & {
  focus: () => void
}

export default Vue.extend({
  props: {
    options: {
      type: Array as () => SelectOption[],
      required: true
    },

    value: {
      type: Object as () => SelectOption | undefined,
      default: undefined
    },

    allowBalance: {
      type: Boolean,
      default: false
    },

    allowLike: {
      type: Boolean,
      default: true
    },

    allowFilter: {
      type: Boolean,
      default: true
    },

    favorite: {
      type: Array as () => string[],
      required: false
    },

    name: {
      type: String as () => FavoriteStorageName,
      default: 'exchange'
    },

    light: {
      type: Boolean,
      default: false
    },

    showNote: {
      type: Boolean,
      default: false
    },

    showIcon: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    visible: boolean
    filterKey: string
  } {
    return {
      visible: false,
      filterKey: ''
    }
  },

  computed: {
    optionFiltered(): SelectOption[] {
      const newOptions = _cloneDeep(this.options)
      const filterKey = this.filterKey.trim().toLowerCase()
      return filterKey
        ? newOptions.filter((item) => {
            return item.label.trim().toLowerCase().includes(filterKey)
          })
        : newOptions
    }
  },

  methods: {
    trigger() {
      if (this.disabled) return

      this.visible = !this.visible
      this.filterKey = ''
      this.$nextTick(() => {
        const filterInputRef = this.$refs.filterInput as FilterInputType
        if (filterInputRef) filterInputRef.focus()
      })
    },

    toggleFavorite(val: string) {
      this.$emit('toggle-favorite', {
        name: this.name,
        val
      })
    },

    hideDropdown() {
      this.visible = false
      this.filterKey = ''
    },

    clickOption(option: SelectOption) {
      this.$emit('input', option)
      this.hideDropdown()
    }
  }
})
