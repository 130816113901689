import axios from 'axios'
import { BaseResponse } from '../base'
import { BorrowHistory, BorrowReq, MarginAsset, RepayHistory, TransferHistory, TransferReq } from './models'

const RESOURCES = Object.freeze({
  marginAsset: '/exchange/margin/assets',
  borrow: '/exchange/borrow',
  repay: '/exchange/repay',
  transfer: '/exchange/transfer'
})

export default {
  getMarginAssets(params: { exchange: string }) {
    return axios.get<BaseResponse<MarginAsset[]>>(RESOURCES.marginAsset, { params })
  },

  borrowRequest(data: BorrowReq) {
    return axios.post<any>(RESOURCES.borrow, data)
  },

  repayRequest(data: BorrowReq) {
    return axios.post<any>(RESOURCES.repay, data)
  },

  transferRequest(data: TransferReq) {
    return axios.post<any>(RESOURCES.transfer, data)
  },

  borrowHistory(params: { exchange: string }) {
    return axios.get<BaseResponse<BorrowHistory[]>>(`${RESOURCES.borrow}/history`, { params })
  },

  repayHistory(params: { exchange: string }) {
    return axios.get<BaseResponse<RepayHistory[]>>(`${RESOURCES.repay}/history`, { params })
  },

  transferHistory(params: { exchange: string }) {
    return axios.get<BaseResponse<TransferHistory[]>>(`${RESOURCES.transfer}/history`, { params })
  }
}
