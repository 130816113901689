





















































































































import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { FormRef, SelectOption } from '@/models/form'
import CrossMarginService from '@/services/crossMargin'
import TerminalCustomSelect from '../../TerminalFormFilter/TerminalCustomSelect.vue'
import { BorrowReq, MarginAsset, TransferReq } from '@/services/crossMargin/models'
import { MESSAGE_STATUS } from '@/store/modules/message/types'

type MODAL_TARGET = 'borrow' | 'repay' | 'transfer'

export default Vue.extend({
  data(): {
    modalTarget: MODAL_TARGET | undefined
    tabPosition: string
    borrowRequest: BorrowReq
    transferRequest: TransferReq
    marginAssets: MarginAsset[]
    marginAssetSelected?: SelectOption
    transferTo: string
    isLoading: boolean
  } {
    return {
      modalTarget: undefined,
      tabPosition: 'crossAccount',
      borrowRequest: {
        exchange: '',
        currency: '',
        amount: 0
      },
      transferRequest: {
        exchange: '',
        currency: '',
        type: 'MAIN_TO_CROSS',
        amount: 0
      },
      marginAssets: [],
      marginAssetSelected: undefined,
      transferTo: '',
      isLoading: false
    }
  },

  components: {
    TerminalCustomSelect
  },

  computed: {
    ...mapState('exchange', ['exchange']),

    borrowFormRules(): Record<string, any[]> {
      return {
        // coin: [{ required: true, message: 'Please select coin', trigger: 'change' }],
        // pair: this.isIsolatedAccount ? [{ required: true, message: 'Please select coin', trigger: 'change' }] : [],
        amount: [
          { required: true, message: 'Please input amount', trigger: 'blur' },
          {
            pattern: new RegExp('^[z0-9]*\\.?[0-9]*$'),
            message: 'Please input number',
            trigger: ['change', 'blur']
          },
          {
            pattern: new RegExp('^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$'),
            message: 'Greater than 0',
            trigger: ['change', 'blur']
          }
        ]
      }
    },

    isIsolatedAccount(): boolean {
      return this.tabPosition === 'isolatedAccount'
    },

    isBorrowRequest(): boolean {
      return this.modalTarget === 'borrow'
    },

    transferOptions(): SelectOption[] {
      return [
        {
          value: 'MAIN_TO_CROSS',
          label: 'Fiat and Spot'
        },
        {
          value: 'CROSS_TO_MAIN',
          label: 'Cross Margin'
        }
      ]
    },

    transferFormRules(): Record<string, any[]> {
      return {
        type: [{ required: true, message: 'Please select from', trigger: ['blur', 'change'] }],
        // currency: [{ required: true, message: 'Please select currency', trigger: ['blur', 'change'] }],
        amount: [
          { required: true, message: 'Please input amount', trigger: 'blur' },
          {
            pattern: new RegExp('^[z0-9]*\\.?[0-9]*$'),
            message: 'Please input number',
            trigger: ['change', 'blur']
          },
          {
            pattern: new RegExp('^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$'),
            message: 'Greater than 0',
            trigger: ['change', 'blur']
          }
        ]
      }
    },

    marginAssetOptions(): SelectOption[] {
      return this.marginAssets.map((item) => ({
        label: item.assetFullName,
        value: item.assetName,
        img: `/images/${item.assetName.toUpperCase()}.jpg`
      }))
    }
  },

  methods: {
    ...mapMutations('message', ['SET_MESSAGE']),

    showModal(target: MODAL_TARGET) {
      this.modalTarget = target
    },

    closeDialog() {
      this.tabPosition = 'crossAccount'
      this.modalTarget = undefined
      this.marginAssetSelected = this.marginAssetOptions[0]
      this.borrowRequest.amount = 0
      this.transferRequest.amount = 0
    },

    async getMarginAssets() {
      if (!this.exchange) return
      this.isLoading = true

      try {
        const { data: assets } = await CrossMarginService.getMarginAssets({ exchange: this.exchange })
        this.marginAssets = assets.data
        if (assets.data.length > 0) {
          this.marginAssetSelected = {
            label: assets.data[0].assetFullName,
            value: assets.data[0].assetName,
            img: `/images/${assets.data[0].assetName.toUpperCase()}.jpg`
          }
        }
      } finally {
        this.isLoading = false
      }
    },

    doBorrowOrRepayRequest() {
      const borrowForm = this.$refs.borrowFormRef as FormRef

      borrowForm.validate((valid: boolean) => {
        if (valid) {
          if (this.modalTarget === 'borrow') {
            this.doBorrowRequest()
          } else if (this.modalTarget === 'repay') {
            this.doRepayRequest()
          }
        } else {
          return false
        }
      })
    },

    async doBorrowRequest() {
      this.isLoading = true

      try {
        await CrossMarginService.borrowRequest(this.borrowRequest)

        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: 'Success!'
        })
        this.closeDialog()
      } finally {
        this.isLoading = false
      }
    },

    async doRepayRequest() {
      this.isLoading = true

      try {
        await CrossMarginService.repayRequest(this.borrowRequest)

        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: 'Success!'
        })
        this.closeDialog()
      } finally {
        this.isLoading = false
      }
    },

    doTransferRequest() {
      const transferForm = this.$refs.transferFormRef as FormRef

      // eslint-disable-next-line space-before-function-paren
      transferForm.validate(async (valid: boolean) => {
        if (valid) {
          this.isLoading = true

          try {
            await CrossMarginService.transferRequest(this.transferRequest)

            this.SET_MESSAGE({
              type: MESSAGE_STATUS.SUCCESS,
              message: 'Success!'
            })
            this.closeDialog()
          } finally {
            this.isLoading = false
          }
        } else {
          return false
        }
      })
    }
  },

  watch: {
    'transferRequest.type': {
      handler(val) {
        this.transferTo = val === 'MAIN_TO_CROSS' ? 'CROSS_TO_MAIN' : 'MAIN_TO_CROSS'
      },
      immediate: true
    },

    exchange: {
      handler() {
        this.getMarginAssets()
        this.borrowRequest.exchange = this.exchange
        this.transferRequest.exchange = this.exchange
      },
      immediate: true
    },

    marginAssetSelected: {
      handler(val) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!val) {
          this.borrowRequest.currency = val.value
          this.transferRequest.currency = val.value
        }
      },
      immediate: true,
      deep: true
    }
  }
})
